.lineContainer {
  display: grid;
  grid-template-columns: var(--grid-columns-parcel-detail);
  min-width: var(--table-min-width);
  max-width: var(--table-max-width-risk-small);
  margin-top: var(--spacing-lg);
}

.lineItemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
  min-width: var(--min-cell-width);
}

.treatmentsTitle {
margin-left: 0px;
position: relative;
}

.lineTitleContainer {
  align-items: flex-start;
}

.itemContainer {
display: grid;
grid-template-columns: repeat(2,  1fr);
justify-content: center;
align-items: center;
gap: 2px;
min-width: var(--min-cell-width);
}

.verticalItemContainer {
flex-direction: column;
}

.treatmentContainer {
padding-top: 2px;
text-align: center;
}

.lineItemsContainer {
display: flex;
justify-content: center;
align-items: center;
text-align: center;
position: relative;
cursor: default;
}

.hoverIcon {
cursor: pointer;
}

.addIcon svg path {
stroke: var(--color-primary);
}

.addIcon {
cursor: pointer;
}

.addIcon svg:hover path {
stroke: var(--color-dark);
}

.addIcon svg {
width: 18px;
height: 18px;
}

.hoverIcon svg {
width: 18px;
height: 18px;
}

.hoverIcon svg {
fill: var(--color-primary);
}

.hoverIcon svg:hover {
fill: var(--color-dark);
}

.deleteIcon {
cursor: pointer;
}

.deleteIcon svg {
width: 18px;
height: 18px;
}

.deleteIcon svg {
fill: var(--color-danger);
}

.deleteIcon svg:hover {
fill: var(--color-danger-dark);
}

.treatmentDeleteCard {
bottom: 0;
}

@media (min-width: 1205px) {
.lineContainer {
grid-template-columns: var(--grid-columns-parcel-detail-medium);
max-width: var(--table-max-width);
}
}
@media (min-width: 1550px) {
.lineContainer {
grid-template-columns: var(--grid-columns-parcel-detail-large);
max-width: var(--table-max-width);
}
}
