.lineContainer {
  display: grid;
  grid-template-columns: var(--grid-columns-parcel-detail);
  min-width: var(--table-min-width);
  max-width: var(--table-max-width-risk-small);
  margin-top: var(--spacing-lg);
}

.lineItemsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: default;
}

.lineItemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: var(--min-cell-width);
}

.titleContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  min-width: var(--min-cell-width);
}

.itemContainer {
  padding-left: 4px;
  align-items: center;
  justify-content: center;
}

.hoverIcon .lineItemContainer .imgContainer .hoverIcon {
  cursor: pointer;
}

.iconContainer {
  display: flex;
  align-items: center;
}

.hoverIcon:hover {
  cursor: pointer;
}

.hoverIcon svg {
  width: 18px;
  height: 18px;
}

.hoverIcon svg path {
  stroke: var(--color-primary);
}

.hoverIcon svg:hover path {
  stroke: var(--color-dark);
}

.deleteIcon svg {
  width: 18px;
  height: 18px;
}

.deleteIcon:hover {
  cursor: pointer;
}

.deleteIcon svg path {
  fill: var(--color-danger);
}

.deleteIcon svg:hover path {
  fill: var(--color-danger-dark);
}

.disabledIcon svg {
  width: 18px;
  height: 18px;
}

.disabledIcon svg path {
  fill: var(--color-disabled);
}

.imgContainer {
  width: 50px;
  height: 50px;
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  padding: 4px 8px;
  bottom: -40px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  color: white;
  min-height: 30px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
}

.fenContainer {
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
}

.fenCard {
  position: absolute;
  top: -110px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: var(--color-light);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-input);
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}

.modalContent {
  padding: var(--spacing-lg);
}

@media (max-width: 600px) {
  .modalContent {
    padding: var(--spacing-md);
  }
}

@media (min-width: 1205px) {
  .lineContainer {
    grid-template-columns: var(--grid-columns-parcel-detail-medium);
    max-width: var(--table-max-width);
  }
}
@media (min-width: 1550px) {
  .lineContainer {
    grid-template-columns: var(--grid-columns-parcel-detail-large);
    max-width: var(--table-max-width);
  }
}
