.container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: var(--color-light);
  display: grid;
  place-content: center;
  font-weight: var(--font-weight-bold);
}

.HIGH {
  background: var(--color-danger);
  box-shadow: 0 0 0 6px rgba(255, 0, 27, 0.5);
}

.MEDIUM {
  background: var(--color-medium);
  box-shadow: 0 0 0 6px rgba(255, 128, 0, 0.5);
}

.LOW {
  background: var(--color-warning);
  box-shadow: 0 0 0 6px rgba(252, 219, 3, 0.5);
}

.VIGILANCE {
  background: var(--color-success);
  box-shadow: 0 0 0 6px rgba(104, 159, 56, 0.5);
}

.NONE {
  background: #e8e3d7;
  box-shadow: 0 0 0 6px rgba(140, 138, 133, 0.5);
}
